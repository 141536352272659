import Vue from 'vue'
import Vuex from 'vuex'

import camera from './modules/camera'
import modals from './modules/modals'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        camera,
        modals,
    },

    state: {

        products: [
            {
                id: 1,
                name: `10ml syringe`,
                header_title: 'pre-filled syringes',
                slug: '10-ml-syringe',
                menu_label: '10 ml SYRINGE',
                container: null,
                load_progress: 0,
            },
            {
                id: 4,
                name: `5ml syringe`,
                header_title: 'pre-filled syringes',
                slug: '5-ml-syringe',
                menu_label: '5 ml SYRINGE',
                container: null,
                load_progress: 0,
            },
            {
                id: 2,
                name: `Blister pack`,
                header_title: 'blister packs',
                slug: 'blister-pack',
                menu_label: 'BLISTER PACK',
                container: null,
                load_progress: 0,
            },
            {
                id: 3,
                name: `Foil pouch and oxygen-absorbing sachet`,
                header_title: 'Adrenaline foil pouch',
                slug: 'foil-pouch',
                menu_label: 'FOIL POUCH',
                container: null,
                load_progress: 0,
            },
        ],

        intro_modal: false,
        reference_modal: false,
        mobile_menu: false,
        auto_rotate: false,
        active_modal: null,
        active_product: 0,
        explode: false,
        loading_screen: true,
        iframe: false,
    },

    getters: {
        products(state) {
            return state.products
        },
        
        get_product(state) {
            return slug => state.products.find(product => product.slug == slug)
        },
        
        active_product(state) {
            return state.active_product
        },

        intro_modal(state) {
            return state.intro_modal
        },
        reference_modal(state) {
            return state.reference_modal
        },
        explode(state) {
            return state.explode
        },
        loading_screen(state) {
            return state.loading_screen
        },
        iframe(state) {
            return state.iframe
        },

        mobile_menu(state) {
            return state.mobile_menu
        },

        active_modal(state) {
            return state.active_modal
        },

        active_modal_data(state) {
            return state.modals.modals.filter(modal => (
                modal.id == state.active_modal &&
                modal.product_id.includes(state.active_product)
            ))[0]
        },
        
        auto_rotate(state) {
            return state.auto_rotate
        },

    },

    mutations: {
        setProductLoadProgress(state, {slug, progress}) {
            let product = state.products.find(product => product.slug == slug)
            if (product) product.load_progress = progress
        },
        setIntroModal(state, id) {
            state.intro_modal = id
        },
        setReferenceModal(state, id) {
            state.reference_modal = id
        },
        setMobileMenu(state, id) {
            state.mobile_menu = id
        },
        setActiveModal(state, id) {
            state.active_modal = id
        },
        setAutoRotate(state, i) {
            state.auto_rotate = i
        },
        setLoadingScreen(state, i) {
            state.loading_screen = i
        },
        setIFrame(state, i) {
            state.iframe = i
        },
        setProductContainer(state, {slug, container}) {
            state.products.find(product => product.slug == slug).container = container
        },

        setActiveProduct(state, slug) {
            let target_product = state.products.find(product => product.slug == slug)
            if (!target_product) return
            Object.values(state.products).forEach(product => {
                product.container?.removeAllFromScene?.()
            })
            target_product?.container?.addAllToScene?.()
            state.active_product = target_product.id
        },

        toggleExplode(state, i) {
            state.explode = !state.explode
        },
    },
})