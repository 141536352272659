<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="157.818" height="228.243" viewBox="0 0 157.818 228.243">
        <g id="Group_115" data-name="Group 115" transform="translate(-1707.549 -620.757)">
            <path id="Path_468" data-name="Path 468"
                d="M1848,801h9.956c3.135,0,8.051,1.99,9.955,4.978h9.956c4.667,0,9.956,4.356,9.956,9.956h9.955c4.667,0,9.955,4.355,9.955,9.956v49.778c0,10.071-9.955,18.183-9.955,24.889v14.933"
                transform="translate(-51.586 -66.489)" fill="none" stroke="#fff" stroke-miterlimit="10"
                stroke-width="6" />
            <line id="Line_29" data-name="Line 29" y2="25.003" transform="translate(1816.897 739.174)" fill="none"
                stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
            <line id="Line_30" data-name="Line 30" y2="20.655" transform="translate(1836.465 748.958)" fill="none"
                stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
            <path id="Path_469" data-name="Path 469"
                d="M1812.811,806.689V746.955a9.956,9.956,0,1,0-19.911,0s0,59.658,0,75.885a1.487,1.487,0,0,1-2.6.97c-3.572-4.048-11.488-13.073-17.307-17.122a13.119,13.119,0,0,0-12.882-.843c-5.772,2.723-6.968,10.386-2.694,15.126,12.863,14.266,17.417,33.395,30.509,45.45,5.237,5.237,4.978,7.156,4.978,9.956v14.933"
                transform="translate(-16.397 -42.311)" fill="none" stroke="#fff" stroke-miterlimit="10"
                stroke-width="6" />
            <path id="Path_470" data-name="Path 470" d="M1800,644.911,1819.911,625l19.911,19.911"
                transform="translate(-33.453 0)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
            <line id="Line_31" data-name="Line 31" y2="50.007" transform="translate(1786.458 625.028)" fill="none"
                stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
            <path id="Path_471" data-name="Path 471" d="M1920,744.822l19.911-19.912L1920,705"
                transform="translate(-78.786 -30.222)" fill="none" stroke="#fff" stroke-miterlimit="10"
                stroke-width="6" />
            <line id="Line_32" data-name="Line 32" x1="50.007" transform="translate(1811.462 694.603)" fill="none"
                stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
            <path id="Path_472" data-name="Path 472" d="M1731.911,744.822,1712,724.91,1731.911,705"
                transform="translate(-0.208 -30.222)" fill="none" stroke="#fff" stroke-miterlimit="10"
                stroke-width="6" />
            <line id="Line_33" data-name="Line 33" x2="50.007" transform="translate(1711.448 694.603)" fill="none"
                stroke="#fff" stroke-miterlimit="10" stroke-width="6" />
        </g>
    </svg>

</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>