export default {
    namespaced: true,
    state: {
        defaults: {
            alpha: 0.74,
            beta: 1.17,
            radius: 20,
            fov: .6,
            upperRadiusLimit: 100,
            lowerRadiusLimit: 12,
            wheelPrecision: 20,
            minZ: 2,
            useAutoRotationBehavior: false,
            autoRotationBehavior: {
                idleRotationSpeed: Math.PI / 16,
                idleRotationWaitTime: 1000,
                idleRotationSpinupTime: 1000,
            },
            useBouncingBehavior: false,

        },

        '10-ml-syringe': {
            "alpha": 0.9154414005196511,
            "beta": 1.1575391759033653,
            range: [20, 50],
            button_range: [1, 1.6]
        },
        '5-ml-syringe': {
            "alpha": 0.9154414005196511,
            "beta": 1.1575391759033653,
            range: [20, 50],
            button_range: [1, 1.6]
        },
        'blister-pack': {
            "alpha": 0.9154414005196511,
            "beta": 1.1575391759033653,
            range: [20, 50],
            button_range: [1, 1.6]
        },
        'foil-pouch': {
            "alpha": 0.9154414005196511,
            "beta": 1.1575391759033653,
            range: [20, 50],
            button_range: [1, 1.6]
        },
    },
    getters: {
        defaults(state) {
            return state.defaults
        },
        '10-ml-syringe'(state) {
            return state['10-ml-syringe']
        },
        '5-ml-syringe'(state) {
            return state['5-ml-syringe']
        },
        'blister-pack'(state) {
            return state['blister-pack']
        },
        'foil-pouch'(state) {
            return state['foil-pouch']
        },
    },
}
