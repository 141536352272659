<template>
    <div id="app">

        <AppHeader/>
        
        <AppNavigation/>

        <transition name="fade">
            <AppIframe v-if="$store.state.iframe"/>
        </transition>

        <transition name="fade">
            <LoadingScreen v-if="loading_screen"/>
        </transition>
        
        <BurgerMenu @click.native="$store.commit('setMobileMenu', true)"/>
        <transition name="fade">
            <MobileNavigation v-if="mobile_menu"/>
        </transition>

        <router-view class="router-view"/>
        
        <footer>
            <AppReferences/>
            <AppInteractions/>
            <div class="app-product-code">
                <!-- AGUK-218 &ndash; 06/21 -->
                <!-- AGUK-218/01 &ndash; 10/21 -->
				AGUK-218/02-07/2024
            </div>
        </footer>
        
        <transition name="fade">
            <AppModal v-if="active_modal"/>
        </transition>
        
        <transition name="fade">
            <IntroModal v-if="intro_modal || reference_modal"/>
        </transition>


        
    </div>

</template>

<script>
import AppIframe from './components/AppIframe'
import LoadingScreen from './components/LoadingScreen'
import MobileNavigation from './components/MobileNavigation'
import BurgerMenu from './components/SVG/BurgerMenu'
import IntroModal from './components/IntroModal'
import AppModal from './components/AppModal'
import AppInteractions from './components/AppInteractions'
import AppReferences from './components/AppReferences'
import AppNavigation from './components/AppNavigation'
import AppHeader from './components/AppHeader'
import { mapGetters } from 'vuex'
export default {
    components: {
        AppHeader,
        AppNavigation,
        AppReferences,
        AppInteractions,
        AppModal,
        IntroModal,
        BurgerMenu,
        MobileNavigation,
        LoadingScreen,
        AppIframe,
    },

    computed: {
        ...mapGetters([
            'reference_modal',
            'active_modal',
            'intro_modal',
            'mobile_menu',
            'loading_screen'
        ]),
    },
}
</script>

<style lang="scss">
    @import './scss/import';

    #app {
        height: 100%;
        width: var(--windowWidth);
        height: var(--windowHeight);
        user-select: none;
    }

    .router-view {
        height: 100%;
        width: 100%;
    }


    #app-header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
    }

    #app-nav {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 5;
    }
    #burger-menu {
        position: fixed;
        top: 32px;
        right: 32px;
        z-index: 5;
        display: none;
    }

    #app-nav {
        display: none;
    }
    #burger-menu {
        display: block;
    }
    @include for-desktop-up {
        #app-nav {
            display: grid;
            }
        #burger-menu {
            display: none;
        }
    }

    #loading-screen {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }


    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: grid;
        grid-template-areas: 'interactions' 'references' 'app-code';
        gap: 16px;
        z-index: 5;
        align-items: end;
        .references {
            grid-area: references;
        }
        #nav-interactions {
            grid-area: interactions;
            justify-self: end;
        }
        @include for-desktop-small-up {
            grid-template-areas: 'app-code references interactions';
            grid-template-columns: auto 1fr auto;
            gap: 32px;
            .references {
                display: block;
            }
        }
        padding: 16px;
        .app-product-code {
            grid-area: app-code;
            font-size: 9px;
            opacity: .6;
        }
    }


    #app-iframe {
        position: absolute;
        z-index: 100;
    }

    

</style>