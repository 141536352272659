<template>
        <div class="modal" :class="{'multi-slide': isMulti}" @click.self="close">

            <IconClose @click.native="close"/>
            
            <IconArrowLeft @click.native="prev()" :class="{'active': !isBeginning}"/>
            <IconArrowRight @click.native="next()" :class="{'active': !isEnd}"/>

            <div class="content">

                <Swiper ref="modal-swiper" :title="active_modal_data.title" :slides="slides" @update-index="i => slideIndex = i"/>
                
            </div>

            <div class="swiper-pagination"/>

        </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IconArrowLeft from './SVG/IconArrowLeft'
import IconArrowRight from './SVG/IconArrowRight'
import IconClose from './SVG/IconClose'
import Swiper from './AppModal/Swiper'
export default {
    components: {
        IconClose,
        IconArrowRight,
        IconArrowLeft,
        Swiper,
    },

    data: () => ({
        slideIndex: -1,
    }),

    computed: {
        ...mapGetters([
            'active_modal',
            'active_modal_data',
            'active_product',
        ]),

        slides() {
            return this.active_modal_data?.slides
        },

        slideCount() {
            return this.active_modal_data?.slides ?
                this.active_modal_data.slides.length :
                1
        },

        isMulti() {
            return this.slideCount > 1
        },

        isBeginning() {
            return this.slideIndex == 0
        },
        isEnd() {
            return this.slideIndex >= this.slideCount - 1
        },
    },

    methods: {
        ...mapMutations([
            'setActiveModal',
        ]),
        close() {
            this.setActiveModal('')
            this.$root.$emit('modal-close')
            this.$emit('close')
        },

        next() {
            this.$refs['modal-swiper']?.next()
        },
        prev() {
            this.$refs['modal-swiper']?.prev()
        },

    },
}
</script>

<style lang="scss" scoped>
.modal {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: var(--windowWidth);
    height: var(--windowHeight);
    background: rgba(#0067a5, .9);
    background: rgba(#0069B4, .94);
    display: grid;
    place-items: center;
    color: white;


    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
    gap: 32px;
    padding: 60px 26px 26px;
    @include for-tablet-landscape-up {
        padding: 60px;
    }

    [class*=icon] {
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            background: rgba(white, .1);
        }
    }

    .icon-close {
        position: absolute;
        padding: 16px;
        box-sizing: content-box;
        top: 0;
        right: 0;
    }

    [class*=icon-arrow] {
        height: 100%;
        width: calc(22px + (16px * 2));
        padding: 0 16px;
        opacity: .2;
        &.active {
            opacity: 1;
        }
    }

    .icon-arrow-right {
        grid-area: nav-right;
    }

    .icon-arrow-left {
        grid-area: nav-left;
    }


    .content {
        grid-area: content;
        width: 100%;
        height: 100%;
        // border: 1px solid rgba(white, .3);
        overflow: hidden;
    }


    [class*=icon-arrow] {
        display: none;
    }

    &.multi-slide {
        grid-template-rows: 1fr auto;
        grid-template-areas: 
            'content'
            'pag'
        ;
        .swiper-pagination {
            display: flex;
        }

        @include for-tablet-landscape-up {
            [class*=icon-arrow] {
                display: block;
            }
    
            grid-template-areas:
                'nav-left content nav-right'
                'pag pag pag'
            ;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: 1fr auto;
        }
    }


    .swiper-pagination {
        display: none;
        justify-content: center;
        grid-area: pag;
        position: relative;

        ::v-deep .swiper-pagination-bullet {
            margin: 8px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: white;
            opacity: .5;
            cursor: pointer;
            transition: all .3s ease;
            display: block;

            &-active {
                opacity: 1;
            }
        }
    }

    .slideshow {
        display: grid;
        user-select: none;
    }

    .single,
    .slide {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

        img {
            margin: 0 auto;
            display: block;
        }
    }
}


</style>