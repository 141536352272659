<template>
    <svg class="icon-arrow-right" xmlns="http://www.w3.org/2000/svg" width="21.928" height="41.814" viewBox="0 0 21.928 41.814">
        <path d="M1002.01,182.577,986.5,164.85l3.634-3.18,18.294,20.907-18.294,20.907L986.5,200.3Z" transform="translate(-986.5 -161.67)"/>
    </svg>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
path {
    fill: white;
}
</style>