<template>
    <div id="app-iframe">
        <iframe :src="$store.state.iframe + '#toolbar=1'" frameborder="0" height="100%" width="100%"></iframe>
        <a id="iframe-close" class="button blue" @click="$store.commit('setIFrame', false)">Close</a>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

    #app-iframe {
        width: var(--windowWidth);
        height: var(--windowHeight);
        padding: 24px;
        background: rgba(white, .9);
        display: grid;
        gap: 24px;
        grid-template-rows: 1fr auto;
        justify-items: end;
    }

    iframe {
        width: 100%;
        height: 100%;
        overflow: auto;
        border: 2px solid $Aguettant_Grey--Medium;
    }

    // #app-iframe {
    //     width: var(--windowWidth);
    //     height: var(--windowHeight);
    //     padding: 24px;
    //     padding-bottom: 24 + 24 + 43px;
    //     background: rgba(white, .9);
    //     display: grid;
    //     place-items: center;
    // }

    // iframe {
    //     width: 100%;
    //     height: 100%;
    //     border: 2px solid $Aguettant_Grey--Medium;
    // }

    // #iframe-close {
    //     position: absolute;
    //     bottom: 24px;
    //     right: 24px;
    //     z-index: 200;
    // }
</style>