<template>
    <header id="app-header">
        <img class="header-graphic" :src="getAsset('AguettantLogo.svg')">

        <HeaderLogoMobile class="header-graphic-mobile"/>
        <div class="header-content">
            <!-- <h1>Aguettant syringes</h1> -->
            <h1>
                Aguettant
                <span v-if="active_product">{{active_product.header_title}}</span>
            </h1>

            <template v-if="active_product && active_product.id == 3">
                <p>The foil pouch protects Aguettant Adrenaline from light and also contains an oxygen-absorbing sachet. This allows a preservative-free (Sulfite-free) formulation in line with the EMEA<sup>1</sup> guideline on excipients.</p>
            </template>
            
            <template v-else>
                <!-- <p>Aguettant ready-to-administer syringes have been designed to help healthcare professionals deliver better, faster and cost-effective patient care.</p> -->
				<!-- <p>Aguettant's ready-to-administer, pre-filled syringes have been designed to help healthcare professionals in their daily medical practises.</p> -->
				<p>Aguettant's ready-to-administer, pre-filled syringes have been designed to help healthcare professionals in their daily practice.</p>
            </template>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderLogoMobile from './SVG/HeaderLogoMobile'
export default {
    components: {
        HeaderLogoMobile,
    },

    computed: {
        ...mapGetters([
            'get_product',
        ]),

        active_product() {
            return this.get_product(this.$route.params.product)
        },
    },
}
</script>

<style lang="scss" scoped>
header {
    padding: 32px;
    display: grid;
    gap: 32px;
    // pointer-events: none;
    align-items: center;
    .header-graphic { display: none; }
    .header-graphic-mobile { display: block; }
    .header-graphic, .header-graphic-mobile { 
        align-self: start;
    }
    h1 {
        margin-bottom: 0;
        font-size: 24px;
    }
    p {
        font-size: 13px;
        display: none;
    }



    grid-template-columns: auto 1fr;
    padding-right: 120px;
    @include for-desktop-small-up {
        align-items: end;
        padding-right: 0px;
        
        .header-graphic { display: block; }
        .header-graphic-mobile { display: none; }

        .header-content {
            max-width: 360px;
        }
        h1 {
            margin-bottom: 16px;
            font-size: 30px;
        }
        p {
            font-size: 15px;
            display: block;
        }
    }
    @include for-desktop-up {
        .header-content {
            max-width: 420px;
        }

    }

}
</style>