<template>
    <svg id="burger-menu" xmlns="http://www.w3.org/2000/svg" width="26" height="14" viewBox="0 0 26 14">
        <line x1="0" y1="0"  x2="26" y2="0"/>
        <line x1="0" y1="6"  x2="26" y2="6"/>
        <line x1="0" y1="12" x2="26" y2="12"/>
    </svg>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
#burger-menu {
    height: 26px;
    width:  26px;
    padding: 16px;
    box-sizing: content-box;
    cursor: pointer;
    background: rgba(white, .2);
}
line {
    stroke-width: 2;
    stroke: #707070;
}
</style>