export default {
    state: {
        modals: [{
            id: `antt`,
            title: `Video`,
            product_id: [1, 2],
            slides: [{
                    title: `Video`,
                    media: 'video/Aguettant 360 Syringe Animation.mp4',
                    // content: `<p>The Aguettant syringe has been evaluated by ASAP as a medical device that promotes safe and effective aseptic technique according to ANTT&reg; principles and practice by design.</p>
                    // <Aseptic>Aguettant uses the international standard - Aseptic Non-Touch Technique (ANTT)<sup>1</sup> Clinical Practice Framework to describe safe aseptic technique. Aseptic Non-Touch Technique (ANTT) can be achieved with Aguettant pre-filled syringes thanks to the sterile blister pack and innovative blue end cap, ensuring no need to touch any Key-Parts.</p>
                    // <br>
                    // <p><sup>1.</sup> Aseptic Non-Touch Technique (ANTT&reg;) Clinical Practice Framework (www.antt.org)</p>`
					content: `<p>This video has been created to show you how to correctly prepare and use Aguettant's ready-to-administer, pre-filled syringe.</p>
					<p>The video starts after the syringe has been removed from the blister/blister and pouch.</p>
					<p>For instruction posters and training resources, please contact us at info@aguettant.co.uk or via your Key&nbsp;Account&nbsp;Manager.</p>`
                },
                // {
                //     title: `Sterile blister pack`,
                //     media: 'images/modals/Sterile-blister-pack.png',
                //     content: `<ul>
                //         <li>Ideal for Standard-ANTT and Surgical-ANTT</li>
                //     </ul>
                //     <p>Aguettant pre-filled syringes are presented in a sterile and rigid semi-permeable blister pack with a printed Tyvek cover. This is ideal for use as a Micro Critical Aseptic Field when using Standard-ANTT for uncomplicated or rapid procedures.</p>
                //     <p>Terminal sterilisation following labelling and packaging allows both the solution and the external surface of the syringe to be sterile. Therefore, the syringes can also be opened onto a main Critical Aseptic Field when using Surgical-ANTT for complicated procedures.</p>
                // `},
                // {
                //     title: `Protective end cap`,
                //     media: 'images/modals/protective-end-cap.png',
                //     content: `<ul>
                //         <li>Promotes Key-Part identification & Key-Part Protection</li>
                //         <li>Offers a reliable finger grip</li>
                //     </ul>
                //     <p>The end cap also serves as a Micro Critical Aseptic Field acting as an integral physical barrier between the Key-Parts and the immediate physical and air environment. It protects the syringe Key-Parts (male luer connector and the solution in the syringe).</p>
                //     <p>The design of the end cap notably facilitates a non-touch approach to practice. The end cap is large, has ridges and textured surfaces for a better and more reliable finger grip, ensuring that the healthcare worker's hands are positioned well away from Key-Parts when the end cap is removed.</p>`,
                // },
            ],
        },
        {
            id: `pre-filled-ready-to-administer`,
            title: `Pre-filled and ready-to-administer`,
            product_id: [1],
            slides: [{
                media: 'images/modals/Pre-filled-and-ready-to-administer.png',
                content: `<ul>
                    <li>Pre-filled with the correct dilution for faster administration.</li>
                    <li>Reduces waste and saves time by eliminating the need to draw up.</li>
                    <li>Reduces risk of administration error.</li>
                </ul>`,
            }, ],
        },
        {
            id: `needle-free`,
            title: `Needle-free`,
            product_id: [1],
            slides: [{
                media: 'images/modals/Needle-free.png',
                // content: `<ul>
                //     <li>Luer lock compatible.</li>
                //     <li>No need to draw up, reducing the risk of sharps injuries.</li>
                //     <li>Key part is protected by our blue cap, so ANTT <sup>1</sup> can be maintained.</li>
                // </ul>
                // <p><sup>1.</sup> Aguettant uses the international standard &ndash; Aseptic Non-Touch Technique (ANTT&reg;) Clinical Practice Framework &ndash; to describe safe aseptic technique (www.antt.org)</p>`,
                content: `<ul>
                    <li>Luer lock compatible.</li>
                    <li>No need to draw up, reducing the risk of sharps injuries.</li>
                    <li>The luer lock compatible connection is protected by a twist&#8209;off blue end cap.</li>
					<li>The twist&#8209;off blue end cap is held in place by a tamper&#8209;evident seal.</li>
                </ul>`,
            }, ],
        },
        {
            id: `polypropylene-material`,
            title: `Polypropylene material`,
            product_id: [1],
            slides: [{
                media: 'images/modals/Polypropylene-material.png',
                content: `<ul>
                    <li>Strong, light and shatter resistant.</li>
                    <li>Clear body to check syringe content.</li>
                </ul>`,
            }, ],
        },
        {
            id: `clear-labelling`,
            title: `Clear labelling`,
            product_id: [1],
            slides: [{
                // media: 'images/modals/Clear-labelling-syringe.png',
				media: 'images/modals/Clear-labelling-syringe--no-acc.png',
                content: `<ul>
                    <!-- <li>Colour&#8209;coded in line with the RCoA<sup>1</sup> guidelines</li> -->
					<li>Colour&#8209;coded in line with the syringe labelling guidelines created by the Association of Anaesthetists, Royal College of Anaesthetists, Intensive Care Society and Faculty of Intensive Care Medicine.<sup>1</sup></li>
                    <li>Clear drug name and concentration.</li>
                </ul>
                <br>
                <!-- <p><sup>1.</sup> Royal College of Anaesthetists. 2014 Syringe labelling in critical care areas review. Available at www.aagbi.org</p> -->
				<p><sup>1.</sup> Association of Anaesthetists (2022). Guidelines Syringe labelling in anaesthesia and critical care areas: review 2022. DOI: <a href="http://dx.doi.org/10.21466/g.SLIAACC.2022" target="_blank">http://dx.doi.org/10.21466/g.SLIAACC.2022</a></p>
			`,
            }],
        },
        {
            id: `tamper-evident-seal`,
            title: `Tamper-evident seal`,
            product_id: [1],
            slides: [{
                media: 'images/modals/Tamper-evident-seal.png',
                // content: `<ul>
                //     <li>Tamper-evident blue cap with a perforated seal</li>
                //     <li>Easy-to-use 'twist&#8209;off cap' with finger grip to ensure Key-Parts remain sterile</li>
                // </ul>`,
                content: `<ul>
                    <li>Tamper-evident blue cap with perforated seal.</li>
					<li>The end cap also serves as an integral physical barrier between the luer lock compatible connection and the immediate and physical air environment.</li>
					<li>The end cap is large, has ridges and textured surfaces for a better and more reliable finger grip, ensuring that the healthcare worker's hands are positioned well away from the luer lock compatible connection when the end cap is removed.</li>
                </ul>`,
            }, ],
        },

        {
            id: `tyvek-label`,
            title: `Tyvek label`,
            product_id: [2],
            slides: [{
                media: 'images/modals/Tyvek-label.png',
                content: `<ul>
                    <!-- <li>Wipeable and liquid-resistant material</li> -->
                    <!-- <li>Creates a microbial barrier</li> -->

					<li>Aguettant pre-filled syringes are presented in a sterile and rigid semi-permeable blister pack with a printed Tyvek cover.</li>
					<li>Wipeable and liquid&#8209;resistant material.</li>
					<li>Creates a microbial barrier.</li>
					<li>Once opened the blister cannot be re-sealed, supporting single use only.</li>
                </ul>`,
            }, ],
        },
        {
            id: `terminally-sterilised`,
            title: `Terminally sterilised`,
            product_id: [2],
            slides: [{
                media: 'images/modals/Terminally-sterilised.png',
                // content: `<ul>
                //     <li>The entire content of the blister is sterile</li>
                //     <li>If the syringe is unused and blister unopened, it can be returned to storage for future use*</li>
                // </ul>
                // <p>*The protocol of use of Aguettant pre-filled syringes remains under the responsibility of the healthcare professional.</p>`,
                content: `<ul>
                    <li>Terminal sterilisation following labelling and packaging allows both the solution and the external surface of the syringe to be sterile.</li>
                    <!-- <li>If the pre-filled syringe (PFS), excluding Adrenaline, is unused and the blister unopened, it can be returned to storage for future use.*</li> -->
                    <li>If the PFS (excluding Adrenaline which must be stored in its foil pouch) is unused and blister unopened, it can be returned to storage for future use.*</li>
                </ul>
                <p>*The protocol of use of Aguettant pre-filled syringes remains under the responsibility of the healthcare professional.</p>`,
            }, ],
        },
        {
            id: `clear-labelling`,
            title: `Clear labelling`,
            product_id: [2],
            slides: [{
                // media: 'images/modals/Clear-labelling-blister.png',
				media: 'images/modals/Clear-labelling-blister--no-acc.png',
                // content: `<ul>
                //     <li>Colour&#8209;coded in line with the RCoA <sup>1</sup> guidelines</li>
                //     <li>Clear drug name and concentration</li>
                //     <li>Easy to open</li>
                // </ul>
                // <br>
                // <p><sup>1.</sup> Royal College of Anaesthetists. 2014 Syringe labelling in critical care areas review. Available at www.aagbi.org</p>`,
				content: `<ul>
                    <!-- <li>Colour&#8209;coded in line with the RCoA<sup>1</sup> guidelines</li> -->
					<li>Colour&#8209;coded in line with the syringe labelling guidelines created by the Association of Anaesthetists, Royal College of Anaesthetists, Intensive Care Society and Faculty of Intensive Care Medicine.<sup>1</sup></li>
                    <li>Clear drug name and concentration.</li>
                </ul>
                <br>
                <!-- <p><sup>1.</sup> Royal College of Anaesthetists. 2014 Syringe labelling in critical care areas review. Available at www.aagbi.org</p> -->
				<p><sup>1.</sup> Association of Anaesthetists (2022). Guidelines Syringe labelling in anaesthesia and critical care areas: review 2022. DOI: <a href="http://dx.doi.org/10.21466/g.SLIAACC.2022" target="_blank">http://dx.doi.org/10.21466/g.SLIAACC.2022</a></p>
			`,
            }, ],
        },
        {
            id: `clear-tray`,
            title: `Clear tray`,
            product_id: [2],
            slides: [{
                media: 'images/modals/Clear-tray.png',
                content: `<ul>
                    <!--<li>Clear blister pack ensures easily identifiable contents</li>-->
                    <li>A clear blister pack allows for easily identifiable contents.</li>
                </ul>`,
            }, ],
        },

        {
            id: `tamper-evident-packaging`,
            title: `Tamper-evident packaging`,
            product_id: [3],
            slides: [{
                media: 'images/modals/Tamper-evident-packaging.png',
                content: `<ul>
                    <li>Heat-sealed for security.</li>
                    <li>Acts as another tamper-evident seal.</li>
                </ul>`,
            }, ],
        },
        {
            id: `easy-to-open`,
            title: `Easy to open`,
            product_id: [3],
            slides: [{
                media: 'images/modals/Easy-to-open.png',
                content: `<ul>
                    <li>Pack opens with a simple tear, no need for scissors.</li>
                </ul>`,
            }, ],
        },
        {
            id: `shelf-life`,
            title: `Shelf life &ndash; Aguettant Adrenaline PFS`,
            product_id: [3],
            slides: [{
                media: 'images/modals/Maximum-shelf-life.png',
                content: `<ul>
                    <li>Foil pouch protects from light, allowing a 2-year shelf life from date of manufacture.</li>
                    <li>Oxygen-absorbing sachet maintains a preservative- and sulfite&#8209;free formulation.</li>
                </ul>`,
            }, ],
        },
        

        {
            id: `antt`,
            title: `Video`,
            product_id: [4],
            slides: [{
                    title: `Video`,
                    media: 'video/Aguettant 360 Syringe Animation.mp4',
                    // content: `<p>The Aguettant syringe has been evaluated by ASAP as a medical device that promotes safe and effective aseptic technique according to ANTT&reg; principles and practice by design.</p>
                    // <Aseptic>Aguettant uses the international standard - Aseptic Non-Touch Technique (ANTT)<sup>1</sup> Clinical Practice Framework to describe safe aseptic technique. Aseptic Non-Touch Technique (ANTT) can be achieved with Aguettant pre-filled syringes thanks to the sterile blister pack and innovative blue end cap, ensuring no need to touch any Key-Parts.</p>
                    // <br>
                    // <p><sup>1.</sup> Aseptic Non-Touch Technique (ANTT&reg;) Clinical Practice Framework (www.antt.org)</p>`

					content: `<p>This video has been created to show you how to correctly prepare and use Aguettant's ready-to-administer, pre-filled syringe.</p>
					<p>The video starts after the syringe has been removed from the blister/blister and pouch.</p>
					<p>For instruction posters and training resources, please contact us at info@aguettant.co.uk or via your Key&nbsp;Account&nbsp;Manager.</p>`
                },
                // {
                //     title: `Sterile blister pack`,
                //     media: 'images/modals/Sterile-blister-pack.png',
                //     content: `<ul>
                //         <li>Ideal for Standard-ANTT and Surgical-ANTT</li>
                //     </ul>
                //     <p>Aguettant pre-filled syringes are presented in a sterile and rigid semi-permeable blister pack with a printed Tyvek cover. This is ideal for use as a Micro Critical Aseptic Field when using Standard-ANTT for uncomplicated or rapid procedures.</p>
                //     <p>Terminal sterilisation following labelling and packaging allows both the solution and the external surface of the syringe to be sterile. Therefore, the syringes can also be opened onto a main Critical Aseptic Field when using Surgical-ANTT for complicated procedures.</p>
                // `},
                // {
                //     title: `Protective end cap`,
                //     media: 'images/modals/protective-end-cap.png',
                //     content: `<ul>
                //         <li>Promotes Key-Part identification & Key-Part Protection</li>
                //         <li>Offers a reliable finger grip</li>
                //     </ul>
                //     <p>The end cap also serves as a Micro Critical Aseptic Field acting as an integral physical barrier between the Key-Parts and the immediate physical and air environment. It protects the syringe Key-Parts (male luer connector and the solution in the syringe).</p>
                //     <p>The design of the end cap notably facilitates a non-touch approach to practice. The end cap is large, has ridges and textured surfaces for a better and more reliable finger grip, ensuring that the healthcare worker's hands are positioned well away from Key-Parts when the end cap is removed.</p>`,
                // },
            ],
        },

        {
            id: `pre-filled-ready-to-administer`,
            title: `Pre-filled and ready-to-administer`,
            product_id: [4],
            slides: [{
                media: 'images/modals/syringe-5ml/Aguettant Syringe 5ml Pre Filled.png',
                content: `<ul>
                    <li>Pre-filled with the correct dilution for faster administration.</li>
                    <li>Reduces waste and saves time by eliminating the need to draw up.</li>
                    <li>Reduces risk of administration error.</li>
                </ul>`,
            }, ],
        },
        {
            id: `needle-free`,
            title: `Needle-free`,
            product_id: [4],
            slides: [{
                media: 'images/modals/syringe-5ml/Aguettant Syringe 5ml Needle Free.png',
				content: `<ul>
				<li>Luer lock compatible.</li>
				<li>No need to draw up, reducing the risk of sharps injuries.</li>
				<li>The luer lock compatible connection is protected by a twist&#8209;off blue end cap.</li>
				<li>The twist&#8209;off blue end cap is held in place by a tamper&#8209;evident seal.</li>
			</ul>`,
            }, ],
        },
        {
            id: `polypropylene-material`,
            title: `Polypropylene material`,
            product_id: [4],
            slides: [{
                media: 'images/modals/syringe-5ml/Aguettant Syringe 5ml Poly Material.png',
                content: `<ul>
                    <li>Strong, light and shatter resistant.</li>
                    <li>Clear body to check syringe content.</li>
                </ul>`,
            }, ],
        },
        {
            id: `clear-labelling`,
            title: `Clear labelling`,
            product_id: [4],
            slides: [{
                // media: 'images/modals/syringe-5ml/Aguettant Syringe 5ml Clear Labelling.png',
                media: 'images/modals/Clear-labelling-syringe-5ml--no-acc.png',
                // content: `<ul>
                //     <li>Colour&#8209;coded in line with the RCoA<sup>1</sup> guidelines</li>
                //     <li>Clear drug name and concentration</li>
                // </ul>
                // <br>
                // <p><sup>1.</sup> Royal College of Anaesthetists. 2014 Syringe labelling in critical care areas review. Available at www.aagbi.org</p>`,
				content: `<ul>
                    <!-- <li>Colour&#8209;coded in line with the RCoA<sup>1</sup> guidelines</li> -->
					<li>Colour&#8209;coded in line with the syringe labelling guidelines created by the Association of Anaesthetists, Royal College of Anaesthetists, Intensive Care Society and Faculty of Intensive Care Medicine.<sup>1</sup></li>
                    <li>Clear drug name and concentration.</li>
                </ul>
                <br>
                <!-- <p><sup>1.</sup> Royal College of Anaesthetists. 2014 Syringe labelling in critical care areas review. Available at www.aagbi.org</p> -->
				<p><sup>1.</sup> Association of Anaesthetists (2022). Guidelines Syringe labelling in anaesthesia and critical care areas: review 2022. DOI: <a href="http://dx.doi.org/10.21466/g.SLIAACC.2022" target="_blank">http://dx.doi.org/10.21466/g.SLIAACC.2022</a></p>
			`,
            }],
        },
        {
            id: `tamper-evident-seal`,
            title: `Tamper-evident seal`,
            product_id: [4],
            slides: [{
                media: 'images/modals/syringe-5ml/Aguettant Syringe 5ml Lid.png',
                // content: `<ul>
                //     <li>Tamper-evident blue cap with a perforated seal</li>
                //     <li>Easy-to-use 'twist&#8209;off cap' with finger grip to ensure Key-Parts remain sterile</li>
                // </ul>`,
				content: `<ul>
                    <li>Tamper-evident blue cap with perforated seal.</li>
					<li>The end cap also serves as an integral physical barrier between the luer lock compatible connection and the immediate and physical air environment.</li>
					<li>The end cap is large, has ridges and textured surfaces for a better and more reliable finger grip, ensuring that the healthcare worker's hands are positioned well away from the luer lock compatible connection when the end cap is removed.</li>
                </ul>`,
            }, ],
        },

    ],
    },
}