<template>
    <div class="references">
        <template v-if="active_product && active_product.id == 3">
            <!-- <p><sup>1.</sup> EMEA, 2007, Guideline on excipients in the dossier for application for marketing authorisation of a medicinal product. Doc. Ref. EMEA/CHMP/QWP/396951/2006</p> -->
			<p style="line-height: 1.4"><sup>1.</sup> EMEA. (2008). Guideline on excipients in the dossier for application for marketing authorisation of a medical product. Available at: <a href="https://www.ema.europa.eu/en/documents/scientific-guideline/guideline-excipients-dossier-application-marketing-authorisation-medicinal-product-revision-2_en.pdf" target="_blank">https://www.ema.europa.eu/en/documents/scientific-guideline/guideline-excipients-dossier-application-marketing-authorisation-medicinal-product-revision-2_en.pdf</a> (Accessed: 22nd July 2024).</p>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'get_product',
        ]),

        active_product() {
            return this.get_product(this.$route.params.product)
        },
    },
}
</script>

<style lang="scss" scoped>
.references {
    ol, ul, p {
        font-size: 9px;
        opacity: .68;
    }
}
</style>