<template>
    <div ref="swiper-container" class="swiper-container">
                    
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(s, i) in slides" :key="i">

                <div class="content-wrap scrollable white">
                    <div class="left">
                        <!-- <img src="https://placekitten.com/740/800" alt=""> -->

                        <template v-if="isVideo(s.media)">

                            <video :src="getAsset(s.media)" loop muted autoplay/>
                            
                        </template>

                        <template v-else>
                            <img :src="getAsset(s.media) || 'https://via.placeholder.com/1000.png?text=Placeholder+modal+image'">
                        </template>
                    </div>
                    <div class="right">
                        <h2 v-html="s.title || title"/>
                        <div v-html="s.content"/>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'
// import Swiper styles
import 'swiper/swiper-bundle.css'

export default {
    props: {
        title: String,
        slides: Array,
    },
    data: () => ({
        slideIndex: 0,
        swiper: null,
        isBeginning: null,
        isEnd: null,
    }),
    mounted() {
        this.slides.length > 1 && this.init()
    },

    methods: {
        init() {
            this.swiper = new Swiper(this.$refs['swiper-container'], {
                loop: !true,
                spaceBetween: 32,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                on: {
                    activeIndexChange: i => {
                        this.slideIndex = i.realIndex
                        this.updateState()
                    },
                },
            })
            this.updateState()
        },
        prev() {
            this.swiper?.slidePrev()
            },
        next() {
            this.swiper?.slideNext()
        },

        updateState() {
            this.isBeginning = this.swiper.isBeginning
            this.isEnd = this.swiper.isEnd
            this.$emit('update-index', this.slideIndex)
        },
    },
    
}
</script>

<style lang="scss" scoped>
.swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {

        .content-wrap {
            overflow: auto;
            display: grid;
            // align-content: start;
            gap: 16px;
            height: 100%;
            
            img {
                margin: 0 auto;
                display: block;
                max-height: 50vh;
                @include for-tablet-landscape-up {
                    max-height: unset;
                }
            }
            video {
                width: 100%;
                height: 100%;
            }

            .left {
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @include for-tablet-landscape-up {
                gap: 32px;
                padding: 32px;
                grid-template-columns: 3fr 2fr;
                // align-content: center;
                align-items: center;
                .left {
                    height: 100%;
                    overflow: hidden;
                }
                img {
                    object-fit: contain;
                    object-position: center;
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }
}

</style>