<template>
    <div id="mobile-nav">
        <nav>
            <router-link v-for="product in products" :key="product.slug"
                :to="`/product/${product.slug}`" v-slot="{ isActive, href }" custom @click.native="closeMenu">
                <a :href="href" :class="{active: isActive}">{{product.menu_label}}</a>
            </router-link>
        </nav>

        <IconClose @click.native="closeMenu"/>
    </div>
</template>

<script>
import IconClose from './SVG/IconClose'
import { mapGetters } from 'vuex'
export default {
    components: {
        IconClose,
    },
    computed: {
        ...mapGetters([
            'products',
        ]),
    },

    methods: {
        closeMenu() {
            this.$store.commit('setMobileMenu', false)
        },
    },
}
</script>

<style lang="scss" scoped>
#mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--windowWidth);
    height: var(--windowHeight);
    z-index: 20;
    background: rgba(white, .9);
    backdrop-filter: blur(4px);
    display: grid;
    gap: 32px;
    padding: 32px;
    place-items: center;
}

nav {
    display: grid;
    align-content: center;
    gap: 32px;
    padding: 32px;
    overflow: auto;
    height: 100%;

    a, .nav-button {
        cursor: pointer;
        text-decoration: none;
        color: $font-colour;
        padding: 4px 0;
        border-bottom: 4px solid transparent;
        font-size: 1.4em;
        &.active {
            border-bottom-color: $Aguettant_Orange;
        }
        text-align: center;
        &:not(:last-child) {
            position: relative;
            margin-bottom: 32px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                bottom: -32px;
                height: 1px;
                width: 100%;
                background: rgba($font-colour, .33);
            }
        }
    }

}
.icon-close {
    height: 26px;
    width:  26px;
    padding: 16px;
    box-sizing: content-box;
    cursor: pointer;
    background: rgba(white, .2);
    ::v-deep path {
        fill: #707070;
    }
}
</style>