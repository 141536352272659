<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57">
        <g id="Group_99" data-name="Group 99" transform="translate(-1629 -971)">
            <text id="ANTT_1" data-name="ANTT 1" transform="translate(1629 987)" fill="#707070" font-size="20" font-family="ITCAvantGardePro-Md, ITC Avant Garde Gothic Pro" font-weight="500">
                <tspan x="0" y="0">ANTT</tspan>
                <tspan y="0" xml:space="preserve" font-size="11.666" baseline-shift="6.666000209254424"> 1</tspan>
            </text>
            <g id="Group_98" data-name="Group 98" transform="translate(1312 701)">
                <path id="Path_359" data-name="Path 359" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(330 297)" fill="#f59c00" />
                <g id="Group_24" data-name="Group 24" transform="translate(-552.008 174.492)">
                    <path id="Path_234" data-name="Path 234"
                        d="M896.228,127.75v8.978H887.25v1.561h8.978v8.978h1.561v-8.978h8.978v-1.561h-8.978V127.75Z"
                        fill="#f2f2f2" fill-rule="evenodd" />
                </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
#Path_359 {
    fill: $Aguettant_Orange;
}
text#ANTT_1 {
    font-family: $font__main--medium;
}
</style>