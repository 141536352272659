<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.148" height="18.864" viewBox="0 0 14.148 18.864">
        <path id="Path_474" data-name="Path 474" d="M235.428,326l4.717,4.721v14.143H226V326Zm-.488,1.179h-7.764v16.5h11.79V331.212Zm1.668,10.611v1.178h-8.253v-1.178Zm0-3.537v1.177h-8.253v-1.177Zm-3.537-3.537V331.9h-4.716v-1.177Z" transform="translate(-225.997 -326.003)" fill="#fff" fill-rule="evenodd"/>
    </svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>