<template>
    <nav id="nav-interactions">


        <!-- <transition-group name="fade" mode="out-in"> -->
            
            <template v-if="interactionCheck('prescribing-information')">
                
				<!-- <a class="button blue" key="prescribing-information-uk" @click="openIframe('assets/resources/Adrenaline 0.1mgml PFS UK PI.pdf')" target="_blank"> UK PI </a>
                <a class="button blue" key="prescribing-information-ireland" @click="openIframe('assets/resources/Adrenaline 0.1 mg ml PFS PI IE Nov 2019.pdf')" target="_blank"> Irish PI </a> -->
                <!-- <a class="button blue" key="prescribing-information" @click="openIframe('https://www.emcpi.com/pi/31592')" target="_blank"> PI </a> -->
                <a class="button blue" key="prescribing-information-uk" @click="openIframe('https://www.emcpi.com/pi/31592')" target="_blank"> UK PI </a>
                <!-- <a class="button blue" key="prescribing-information-ireland" @click="openIframe('assets/resources/Adrenaline 0.1 mg ml PFS PI IE Nov 2019.pdf')" target="_blank"> Irish PI </a> -->
                <a class="button blue" key="prescribing-information-ireland" @click="openIframe('assets/resources/PI Adrenaline 1mg-10ml PFS IE.pdf')" target="_blank"> Irish PI </a>
                
            </template>
            
            <template v-if="interactionCheck('antt')">
                <a class="button blue" key="antt" @click="handleClick('antt')">
                    Please click here for the training video
                </a>
                <!-- <ButtonANTT class="interaction" key="antt" @click.native="handleClick('antt')"/> -->
            </template>
        
            <template v-if="interactionCheck('explode')">
                <div key="explode" class="button blue" @click="handleClick('explode')">
                    Expand
                </div>
                <!-- <div key="explode" class="button large round blue interaction" @click="handleClick('explode')">
                    <IconExplode/>
                </div> -->
            </template>

            <template v-if="interactionCheck('rotate')">
                <div key="rotate" class="button blue" @click="handleClick('rotate')">
                    Rotate
                </div>
                <!-- <div key="rotate" class="button large round blue interaction" @click="handleClick('rotate')">
                    <IconRotate/>
                </div> -->
            </template>

        <!-- </transition-group> -->

    </nav>
</template>

<script>
import ButtonPI from './SVG/ButtonPI'
import IconReference from './SVG/IconReference'
import ButtonANTT from './SVG/ButtonANTT'
import IconRotate from './SVG/IconRotate'
import IconExplode from './SVG/IconExplode'
export default {
    components: {
        IconExplode,
        IconRotate,
        ButtonANTT,
        IconReference,
        ButtonPI,
    },

    data: () => ({

        interactions: {
            'antt': [
                '10-ml-syringe',
                '5-ml-syringe',
                'blister-pack',
            ],
            'explode': [
                '10-ml-syringe',
                '5-ml-syringe',
            ],
            'rotate': [
                '10-ml-syringe',
                '5-ml-syringe',
                'blister-pack',
                'foil-pouch',
            ],
            'prescribing-information': [
                'foil-pouch',
            ],
        },

    }),

    computed: {
        auto_rotate() {
            return this.$store.getters.auto_rotate
        },
    },
    methods: {
        interactionCheck(name) {
            return this.interactions[name].includes(this.$route.params.product)
        },

        handleClick(name) {
            this.$root.$emit('interaction', name)
        },
    },
}
</script>

<style lang="scss" scoped>
#nav-interactions {

    // display: grid;
    // grid-auto-flow: column;
    // grid-template-columns: auto;
    // gap: 16px;

    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
        margin: 8px;
    }

    .interaction {
        cursor: pointer;
        height: 42px;
        @include for-desktop-small-up {
            height: 68px;
        }
    }
}


@include for-desktop-small-up {
    #button-references {
        display: none;
    }
}
</style>