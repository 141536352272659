<template>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="42.094" height="36.031" viewBox="0 0 42.094 36.031"> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="42.094" height="36.031" viewBox="0 0 42.094 42.094">
        <g id="icon-explode" transform="translate(1.366 1)">
            <line id="Line_6" data-name="Line 6" y1="5.273" x2="9.134" transform="translate(0 27.246)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_238" data-name="Path 238" d="M401.664,2187.376l-4.8-1.287,1.287-4.8" transform="translate(-396.862 -2153.57)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="Line_7" data-name="Line 7" x1="9.134" y1="5.273" transform="translate(30.228 27.246)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_239" data-name="Path 239" d="M711.426,2187.376l4.8-1.287-1.287-4.8" transform="translate(-676.866 -2153.57)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_240" data-name="Path 240" d="M526.141,2081.05l-7.02,3.51-7.02-3.51" transform="translate(-499.44 -2064.345)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="Line_8" data-name="Line 8" y2="8.789" transform="translate(19.681 20.215)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="Line_9" data-name="Line 9" y2="10.547" transform="translate(19.681)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_241" data-name="Path 241" d="M551.031,1932.516,547.516,1929,544,1932.516" transform="translate(-527.835 -1929)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="Line_10" data-name="Line 10" y1="3.806" x2="7.626" transform="translate(19.681 28.714)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_242" data-name="Path 242" d="M610.773,2043.341v-8.954l-7.557-3.778" transform="translate(-580.545 -2019.445)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <line id="Line_11" data-name="Line 11" x1="7.626" y1="3.806" transform="translate(12.055 28.714)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_243" data-name="Path 243" d="M480,2043.335v-8.954l7.558-3.779" transform="translate(-470.866 -2019.44)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
    </svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>