import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/product/10-ml-syringe'
    },
    {
        path: '/product/:product',
        component: () => import('../views/Products'),
        props: true,
    },
    
]

const router = new VueRouter({
    routes
})

export default router