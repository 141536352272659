export default {

    methods: {
        
        navTo(path) {
            if (path !== this.$route.fullPath) {
                this.$router.push(path)
            }
        },

        getAsset(path) {
            try {
                return require( `@/assets/${path}` )
            } catch (error) {
                return false
            }
        },

        isVideo(path) {
            return path && path.match(/\.mp4$/)
        },

        openExternal(path) {
            window.open(path, '_blank')
        },


        openIframe(path) {
            console.log(this.$store.commit('setIFrame', path))
            console.log('Iframe requested:', path)
        },
    
    },

}