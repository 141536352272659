<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Group_129" data-name="Group 129" transform="translate(-330 -297)">
            <path id="Path_359" data-name="Path 359" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
                transform="translate(330 297)" />
            <g id="Group_24" data-name="Group 24" transform="translate(-552.008 174.492)">
                <path id="Path_234" data-name="Path 234"
                    d="M896.228,127.75v8.978H887.25v1.561h8.978v8.978h1.561v-8.978h8.978v-1.561h-8.978V127.75Z"
                    fill="#f2f2f2" fill-rule="evenodd" />
            </g>
        </g>
    </svg>

</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
#Path_359 {
    fill: $Aguettant_Orange;
}
</style>