<template>
    <svg class="icon-arrow-left" xmlns="http://www.w3.org/2000/svg" width="21.928" height="41.815" viewBox="0 0 21.928 41.815">
        <path d="M992.917,182.577l15.511-17.727-3.634-3.18L986.5,182.577l18.294,20.907,3.634-3.18Z" transform="translate(-986.5 -161.67)"/>
    </svg>

</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
path {
    fill: white;
}
</style>