<template>
        <div class="modal" @click.self="close">

            <IconClose @click.native="close"/>
            
            <div class="content">
                <IntroGraphic class="graphic"/>
                <div class="text">
                    <p>Press and hold to move the model</p>
                    <p>Tap the hotspots <HotspotExample/> to find out more</p>
                    <button class="orange" @click="close">Okay</button>
                </div>
            </div>

        </div>
</template>

<script>
import IntroGraphic from './SVG/IntroGraphic'
import HotspotExample from './SVG/HotspotExample'
import { mapMutations } from 'vuex'
import IconClose from './SVG/IconClose'
export default {
    components: {
        IconClose,
        HotspotExample,
        IntroGraphic,
    },

    methods: {
        ...mapMutations([
            'setIntroModal',
            'setReferenceModal',
        ]),

        close() {
            this.$emit('close')
            this.setIntroModal(false)
            this.setReferenceModal(false)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: var(--windowWidth);
    height: var(--windowHeight);
    display: grid;
    place-items: center;
    color: white;


    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
    padding: 60px;

    backdrop-filter: blur(4px);
    background: rgba(white, .5);

    [class*=icon] {
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            background: rgba(white, .1);
        }
    }

    .icon-close {
        position: absolute;
        padding: 16px;
        box-sizing: content-box;
        top: 0;
        right: 0;
    }

    .content {
        background: rgba(#0067a5, .9);
        padding: 32px;
        min-height: 300px;
        
        width: 100%;
        max-width: 640px;

        display: grid;
        gap: 48px;
        justify-items: center;

        @include for-tablet-landscape-up {
            grid-template-columns: 3fr 7fr;
        }

        font-size: 30px;
        font-family: $font__main--book;
        p svg {
            height: 1em;
            vertical-align: middle;
        }
    }

}


</style>