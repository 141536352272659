<template>
    <svg class="icon-close" xmlns="http://www.w3.org/2000/svg" width="27.895" height="27.921" viewBox="0 0 27.895 27.921">
        <path id="Path_360" data-name="Path 360" d="M824.784,127.68l-1.559,1.554,12.357,12.395L823.17,154.037l1.559,1.557,12.407-12.406,12.37,12.413,1.559-1.552-12.374-12.415L851.03,129.3l-1.557-1.554-12.334,12.333Z" transform="translate(-823.17 -127.68)" fill-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
path {
    fill: white;
}
</style>