<template>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="27.894" height="31.33" viewBox="0 0 27.894 31.33"> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="27.894" height="31.33" viewBox="0 -2 27.894 33.33">
        <g id="icon-rotate" transform="translate(-1015.5 -161.619)">
            <path id="Path_473" data-name="Path 473" d="M1019.584,165.269l1.644,1.644a11.579,11.579,0,0,0,7.33,19.748h0l-2.4-3.017,1.818-1.448,4.763,5.988-5.986,4.766-1.448-1.82,2.678-2.132A13.962,13.962,0,0,1,1015.5,175.13,14.379,14.379,0,0,1,1019.584,165.269Zm0,0c3.955-3.2,6.629-3.807,11.456-3.619,7.344.679,12.353,8.435,12.353,13.48a13.856,13.856,0,0,1-4.085,9.861h0l-1.643-1.644a11.549,11.549,0,0,0,3.4-8.218c0-5.775-5.443-10.995-9.262-11.457.084.006-7.04-1.055-10.579,3.239Z" transform="translate(0 0)" fill="#f2f2f2" fill-rule="evenodd"/>
        </g>
    </svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>