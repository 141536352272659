import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import mixins from './mixins/mixins'
Vue.mixin(mixins)


Vue.config.productionTip = false

import * as BABYLON from 'babylonjs'
import * as GUI from 'babylonjs-gui'
BABYLON.GUI = GUI
import 'babylonjs-loaders'



/* bling.js */

// window.$ = document.querySelectorAll.bind(document);

Node.prototype.on = window.on = function (name, fn) {
    this.addEventListener(name, fn);
}
NodeList.prototype.__proto__ = Array.prototype;
NodeList.prototype.on = NodeList.prototype.addEventListener = function (name, fn) {
    this.forEach(function (elem, i) {
        elem.on(name, fn);
    });
}


window.app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        this.resizeApp()
        window.addEventListener('resize', () => this.resizeApp())
        document.addEventListener('keydown', (e) => {
            if (e.keyCode == 27) {
                if (confirm('Are you sure you would like to quit this app?')) {
                    window.close()
                }
            }
        })
    },

    methods: {
        resizeApp() {
            document.documentElement.style.setProperty('--windowWidth', window.innerWidth + "px");
            document.documentElement.style.setProperty('--windowHeight', window.innerHeight + "px");
        },
    },
}).$mount('#app')
