<template>
    <nav id="app-nav">
        <router-link v-for="product in products" :key="product.slug" :to="`/product/${product.slug}`" v-slot="{ isActive, href }" custom>
            <a :href="href" :class="{active: isActive}">{{product.menu_label}}</a>
        </router-link>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'products',
        ]),
    },
}
</script>

<style lang="scss" scoped>
nav {
    display: grid;
    grid-auto-flow: column;
    gap: 32px;
    padding: 32px;

    a, .nav-button {
        cursor: pointer;
        text-decoration: none;
        color: $font-colour;
        padding: 4px 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        &.active {
            border-bottom-color: $Aguettant_Orange;
        }

        &:not(:last-child) {
            margin-right: 32px;
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: -32px;
                top: 50%;
                height: 42px;
                transform: translateY(-50%);
                width: 1px;
                background: rgba($font-colour, .33);
            }
        }
    }
}
</style>